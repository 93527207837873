<template>
  <div class="layout-contaner bgf8">
    <div class="contaner bgf8">
      <div class="contaner-box bgf">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YoufujichuangLayout",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.layout-contaner {
  width: 100%;
  .contaner {
    min-width: 1200px;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .contaner-box {
    box-sizing: border-box;
    padding: 32px;
  }
}
</style>