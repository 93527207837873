<template>
  <div class="intention-contaner">
    <Header></Header>
    <Nav></Nav>
    <Layout>
      <el-row class="pb16" type="flex" align="middle" justify="space-between">
        <div class="left f22 c3">填写意向书</div>
      </el-row>
      <div class="line"></div>
      <el-form
        label-width="79px"
        :model="ruleForm"
        :status-icon="false"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
      >
        <!-- 姓名: -->
        <el-form-item label="姓名:" required prop="name">
          <el-input
            type="text"
            maxlength="10"
            v-model="ruleForm.name"
            placeholder="请输入您的姓名"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 姓名: -->
        <!-- 联系电话:: -->
        <el-form-item label="联系电话:" required prop="phone">
          <el-input
            type="phone"
            maxlength="11"
            v-model="ruleForm.phone"
            placeholder="请输入联系电话"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 联系电话:: -->
        <!-- 公司名称:: -->
        <el-form-item label="公司名称:" required prop="comp">
          <el-input
            type="text"
            maxlength="20"
            v-model="ruleForm.comp"
            placeholder="请输入您的公司名称"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--公司名称: -->
        <!-- 地区:: -->
        <el-form-item class="mb20" label="地区:" required prop="area">
          <el-row class="check" type="flex" align="middle">
            <!-- <el-select v-model="ruleForm.area" placeholder="请选择您的地区">
              <el-option
                v-for="item in areaData"
                :key="item.value"
                :value="item.value"
              >
                <span style="float: left; color: #8492a6; font-size: 13px">{{
                  item.value
                }}</span>
              </el-option>
            </el-select> -->
            <el-cascader
              :options="options"
              :props="props"
              @change="posHandle"
            ></el-cascader>
          </el-row>
        </el-form-item>
        <!-- 地区::结束 -->
        <!-- 备注;: -->
        <el-form-item class="mb20" label="备注:" required prop="remark">
          <el-input
            type="textarea"
            maxlength="300"
            v-model="ruleForm.remark"
            placeholder="请输入您的特殊备注内容"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- 备注;:结束 -->
        <!-- 提交开始 -->
        <el-form-item>
          <el-button class="btn cf f16" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
        <!-- 提交结束 -->
      </el-form>
    </Layout>
    <Footer></Footer>
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc-intention"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">提交成功</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p class="text">
          您已成功提交意向清单请耐心等待平台安排服务人员与您联系
        </p>
        <div class="btn-box">
          <button class="btn" @click="confirm">确认</button>
        </div>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  name: "YoufujichuangIntention",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    // var validateName = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入姓名"));
    //   }
    //   callback();
    // };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系电话"));
      } else {
        if (!/^1[3-9]\d{9}$/.test(this.ruleForm.phone)) {
          callback(new Error("手机号格式不正确"));
        }
      }
      callback();
    };
    // var validateComp = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入您的公司名称"));
    //   }
    //   callback();
    // };
    // var validateArea = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请选择您的地区"));
    //   }
    //   callback();
    // };
    // var validateRemark = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入您的特殊备注内容"));
    //   }
    //   callback();
    // };
    return {
      assderVisible: false, //成功弹窗
      ruleForm: {
        name: "",
        phone: "", //电话
        comp: "", //公司名称
        area: "", //地区
        remark: "", //备注
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入您的姓名",
            trigger: ["blur", "change"],
          },
        ],
        phone: [{ validator: validatePhone, trigger: ["blur", "change"] }],
        comp: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: ["blur", "change"],
          },
        ],
        area: [
          {
            required: true,
            message: "请选择地区",
            trigger: ["blur", "change"],
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入特殊备注内容",
            trigger: ["blur", "change"],
          },
        ],
      },
      areaData: [
        {
          value: "北京",
        },
      ],
      id: "", //商品id

      // 地区选择器
      props: { value: "id", label: "fullname", children: "areaTrees" },
      options: [],
    };
  },

  mounted() {},

  created() {
    this.id = this.$route.query.id;

    //查询省市区
    this.getArea();
  },

  methods: {
    //查询省市区
    getArea() {
      this.$API
        .getArea()
        .then((res) => {
          if (res.code == 1) {
            // this.options = res.data;
            this.options = res.data.map((item) => {
              // 第一级，市
              // item.areaTrees 第二级，区
              item.areaTrees = item.areaTrees.map((secondItem) => {
                // secondItem
                secondItem.areaTrees = secondItem.areaTrees.map((thirdItem) => {
                  delete thirdItem.areaTrees;
                  return thirdItem;
                });
                return secondItem;
              });

              return item;
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 确认
    confirm() {
      // this.$router.push("/detail");
      setTimeout(() => {
        this.$router.back();
      }, 500);
    },
    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },

    // 省市区改变
    posHandle(a) {
      this.ruleForm.area = a[0];
      this.ruleForm.provinceCode = a[0];
      this.ruleForm.cityCode = a[1];
      this.ruleForm.areaCode = a[2];
    },

    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          // 选中省市区对应名称的获取
          let selectProvince = {};
          let selectCity = {};
          let selectArea = {};
          this.options.forEach((item) => {
            if (item.id == this.ruleForm.provinceCode) {
              selectProvince = item;
            }
          });
          selectProvince.areaTrees.forEach((item) => {
            if (item.id == this.ruleForm.cityCode) {
              selectCity = item;
            }
          });
          selectCity.areaTrees.forEach((item) => {
            if (item.id == this.ruleForm.areaCode) {
              selectArea = item;
            }
          });

          // 商品购买意向-添加
          let paramData = {
            fullName: this.ruleForm.name,
            mobile: this.ruleForm.phone,
            companyName: this.ruleForm.comp,
            remarks: this.ruleForm.remark,
            goodsId: this.id,
            provinceCode: this.ruleForm.provinceCode,
            cityCode: this.ruleForm.cityCode,
            areaCode: this.ruleForm.areaCode,
            provinceName: selectProvince.fullname,
            cityName: selectCity.fullname,
            areaName: selectArea.fullname,
          };
          // console.log(paramData);
          // return;
          this.$API
            .addBuyIntention(paramData)
            .then((res) => {
              if (res.code == 1) {
                this.assderVisible = true;
              } else {
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.intention-contaner {
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  //   form表单
  .demo-ruleForm {
    margin-top: 46px !important;
    margin-left: 322px !important;
    .btn {
      width: 220px;
      height: 50px;
      background: linear-gradient(90deg, #ed1b23 0%, #f15f64 100%);
      box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      font-size: 16px;
      margin-top: 68px;
      margin-left: 52px;
    }
  }
  .el-input__inner {
    width: 240px;
    height: 40px;
  }
  .el-form-item__label {
    text-align: left;
  }
  .el-textarea__inner {
    width: 400px;
    height: 120px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
  }
  // 自定义弹窗样式
  .assderTc-intention {
    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }
    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }
    display: flex;
    align-items: center;
    .title {
      padding-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      img {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 24px;
        right: 32px;
      }
    }
    ::v-deep .el-dialog__header {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 0 !important;
    }
    ::v-deep .el-dialog__body {
      padding: 0;
    }
    ::v-deep .el-dialog__footer {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 10px;
    }
    .ja-c {
      height: auto !important;
      margin: 0 auto;
      margin-top: 24px;
      padding: 0 !important;
      .text {
        display: inline-block !important;
        width: 600px;
        height: 45px;
        box-sizing: border-box;
        padding: 0 32px;
        font-size: 16px;
        color: #666666;
        line-height: 24px;
        // margin-left: 62px;
        text-align: center;
      }
      .btn-box {
        display: flex;
        justify-content: center;
      }
      .btn {
        width: 140px;
        height: 42px;
        background: linear-gradient(96deg, #ed1b23 0%, #f15f64 100%);
        box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
        border-radius: 8px 8px 8px 8px;
        color: #ffffff;
        margin-top: 37px;
        margin-bottom: 32px;
        // margin-left: 160px;
      }
    }

    .btnad {
      width: 112px;
      height: 40px;
      background: rgba(246, 247, 249, 1);
      border: 1px solid #ecedef;
      opacity: 1;
      border-radius: 20px;
    }
    .btnsad {
      color: #333;
      width: 112px;
      height: 40px;
      background: rgba(255, 195, 44, 1);
      opacity: 1;
      border-radius: 20px;
    }
  }
}
</style>